import type { NextPage } from 'next';
import Head from 'next/head';
import { PubSub, EVENTS, LANGUAGES, COUNTRIES, API_ENVS } from '@rc-d2c/context-manager';
import { RcButton } from '@rc-d2c/shared-ui/RcButton';
import { RcSelect } from '@rc-d2c/shared-ui/RcSelect';
import AddToCartCard from '../components/AddToCartCard';
import styles from '../styles/common.module.scss';
import { useLocalStorage } from 'usehooks-ts';
import { getDefaultStore } from '../helpers/store';
import { getCurrentEnv } from '../helpers/getCurrentEnv';

const defaultLanguageForStoreMapping: Record<COUNTRIES, LANGUAGES> = {
  [COUNTRIES.FR]: LANGUAGES.frFR,
  [COUNTRIES.NL]: LANGUAGES.nlNL,
  [COUNTRIES.DE]: LANGUAGES.deDE,
  [COUNTRIES.SE]: LANGUAGES.svSE,
  [COUNTRIES.TR]: LANGUAGES.trTR,
  [COUNTRIES.US]: LANGUAGES.enUS,
  [COUNTRIES.GB]: LANGUAGES.enGB,
  // @deprecated [6d5f59e4-4162-4286-a6ae-788520cb127b]
  [COUNTRIES.UK]: LANGUAGES.enGB,
  // deprecated END
  [COUNTRIES.IT]: LANGUAGES.itIT,
};

const switchLanguageSelectOptions = Object.keys(LANGUAGES).map((key) => ({
  value: LANGUAGES[key as keyof typeof LANGUAGES],
  content: key,
}));

const renaissanceCountries = [COUNTRIES.IT];

const storeSelectOptions =
  process.env.NEXT_PUBLIC_LEGACY_APIS === 'true'
    ? Object.keys(COUNTRIES)
        .filter((country) => !renaissanceCountries.includes(country as COUNTRIES))
        .map((key) => ({
          value: COUNTRIES[key as keyof typeof COUNTRIES],
          content: key,
        }))
    : renaissanceCountries.map((item) => ({
        value: item,
        content: item,
      }));

// create an array of objects with value and content properties from LANGUAGES enum

const Home: NextPage = () => {
  const [store, setStore] = useLocalStorage<COUNTRIES>('app-shell-store', getDefaultStore());
  const [language, setLanguage] = useLocalStorage<LANGUAGES>('app-shell-language', LANGUAGES.frFR);

  const submitLanguage = () => {
    PubSub.publish(EVENTS.SET_GLOBAL_SETTINGS_CONFIG, {
      country: store,
      language,
      withLegacyApis: process.env.NEXT_PUBLIC_LEGACY_APIS === 'true',
      apiEnv: API_ENVS.STG,
      routes: {
        HOMEPAGE: '/',
        CONTACT_SUPPORT: '/',
      },
    });

    window.location.reload();
  };

  const submitStore = () => {
    const defaultStoreLanguage = defaultLanguageForStoreMapping[store];
    setLanguage(defaultStoreLanguage);
    PubSub.publish(EVENTS.SET_GLOBAL_SETTINGS_CONFIG, {
      country: store,
      apiEnv: getCurrentEnv(),
      language: defaultStoreLanguage,
      withLegacyApis: process.env.NEXT_PUBLIC_LEGACY_APIS === 'true',
      routes: {
        HOMEPAGE: '/',
        CONTACT_SUPPORT: '/',
      },
    });

    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <Head>
        <title>Shell next 13</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className={styles.main}>
        <div className={styles.blockWrapper}>
          <h3 className={styles.blockWrapperTitle}>Switch Store</h3>
          <RcSelect
            name="store"
            options={storeSelectOptions}
            onChange={({ target: { value } }) => setStore(value as COUNTRIES)}
            value={store}
            label="Store"
            translations={{
              scrollDownAria: 'Scroll to down',
              scrollUpAria: 'Scroll to up',
            }}
          />
          <RcButton onClick={submitStore} label={'Submit'} />
        </div>
        <div className={styles.blockWrapper}>
          <h3 className={styles.blockWrapperTitle}>Switch Language</h3>
          <RcSelect
            name="language"
            options={switchLanguageSelectOptions}
            onChange={({ target: { value } }) => setLanguage(value as LANGUAGES)}
            value={language}
            label="language"
            translations={{
              scrollDownAria: 'Scroll to down',
              scrollUpAria: 'Scroll to up',
            }}
          />
          <RcButton onClick={submitLanguage} label={'Submit'} />
        </div>
        <div>
          <div className={styles.blockWrapper}>
            <h3>Cats</h3>
            <div className={styles.cardContainer}>
              <AddToCartCard
                title="Kitten Sterilised"
                payload={{
                  mainItem: '2562',
                  ean: '3182550784511',
                  skuId: '8a7081d4818c62cc0181946522a40489',
                  quantity: 1,
                  isSubscriptionChecked: false,
                }}
              />
              <AddToCartCard
                title="Indoor 27"
                payload={{
                  mainItem: '2529',
                  ean: '3182550704625',
                  skuId: '8a7081d4818c62cc0181946504b903c5',
                  quantity: 3,
                  isSubscriptionChecked: true,
                  periodTypeId: '61708',
                }}
              />
            </div>
          </div>
          <div className={styles.blockWrapper}>
            <h3>Dogs</h3>
            <div className={styles.cardContainer}>
              <AddToCartCard
                title="Mini Puppy"
                payload={{
                  mainItem: '3000',
                  skuId: '8a7081d4818c62cc018194649e460121',
                  ean: '3182550932707',
                  quantity: 1,
                  isSubscriptionChecked: false,
                }}
              />
              <AddToCartCard
                title="Medium Starter Mother & Babydog"
                payload={{
                  mainItem: '2993',
                  skuId: '8a7081d4818c62cc01819464b41f01ad',
                  ean: '3182550932714',
                  quantity: 1,
                  isSubscriptionChecked: true,
                  periodTypeId: '61711',
                }}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
